import expression from "../media/expression.jpg"
import Title from "../features/Title";
import Youtube from "../features/Youtube"

function Basics() {
    return (
        <div className="m-8">
            <Title>
                <h2 className="text-2xl">Basics</h2>
            </Title>
            <img src={expression} alt="Algebraic Expression Diagram" className="w-[560px] max-w-full" />
            <sub>© James Blair 2022</sub>

            <h3 className="text-xl font-bold mt-16 mb-4">Working with Variables</h3>

            <p className="mb-2">
                If you want to add a variable to a constant, you would simply use a plus (+) sign.<br />
                <b>Example:</b> x+2
            </p>
            <p className="mb-2">
                If you wanted to multiply a variable, you would leave out the multiply (✕) sign. This would make the number a coefficient.<br />
                <b>Example:</b> 2✕n → 2n
            </p>
            <p className="mb-2">
                If you wanted to subtract a variable, you would simply use a minus (-) sign.<br />
                <b>Example:</b> n-5
            </p>
            <p className="mb-2">
                If you wanted to divide variables/constants, you would turn it into a fraction.<br />
                <b>Example:</b> n÷2 = <sup>n</sup>&frasl;<sub>2</sub>
            </p>
            <p className="mb-2">
                If you wanted to double a variable, you would add a 2 before it.<br />
                <b>Example:</b> 2 n = 2n
            </p>
            <p className="mb-2">
                If you wanted to triple a variable, you would add a 3 before it.<br />
                <b>Example:</b> 3n = 3n
            </p>

            <h3 className="text-xl font-bold mt-16 mb-4">Simplifying Expressions</h3>

            <p className="mb-2">
                To simplify, you have to combine like terms.<br />
                Like terms are terms that share the same variable, and the same power/exponent.
            </p>
            <p className="mb-2">
                <b>Examples of like terms include:</b>
            </p>
            <ul className="list-disc ml-10 my-3">
                <li>b+b+b-b</li>
                <li>2b+b</li>
                <li>3z+5z</li>
            </ul>
            <p className="mb-2">To combine like terms, you have to add the like terms together.</p>
            <p className="mb-2"><b>Examples of like terms combined:</b></p>
            <ul className="list-disc ml-10 my-3">
                <li>b+b+b-b=2b</li>
                <li>2b+b=3b</li>
                <li>3z+5z=8z</li>
            </ul>
            <p className="mb-2">Expressions can be added/subtracted through combining like terms, but they can also be multiplied and divided.</p>
            <p className="mb-2">When you multiply two like terms together, you have to square the two like terms.</p>
            <p className="mb-2">2*2=2<sup>2</sup></p>
            <p className="mb-10">The small number, called the exponent or power, tells us how many times the number has been multiplied by itself.</p>
            <Youtube id="94-tVJ6EvUs" />
        </div>
    );
}

export default Basics;
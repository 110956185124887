import Title from "../features/Title";
import foil from "../media/foil.jpg"

function Multiplication() {
    return (
        <div className="m-8">
            <Title>
                <h2 className="text-2xl">Multiplication</h2>
            </Title>

            <h3 className="text-xl font-bold mt-16 mb-4">Multiplying Variables</h3>

            <p className="mb-5">When multiplying variables, you leave out the multiply (✕) sign. This is because we like to keep it simple when we are doing Algebra.</p>

            <p className="mb-5">2×n
            <br />= 8n</p>

            <p className="mb-5">a×x
            <br />= ax</p>

            <h3 className="text-xl font-bold mt-16 mb-4">Exponents</h3>

            <p className="mb-5">When multiplying two variables with the same letter, you add a small number at the top (called an exponent). The exponent tells you how many times a number is multiplied by itself.</p>

            <p className="mb-5">y×y
            <br />= y<sup>2</sup></p>

            <p className="mb-5">z×z×z
            <br />= z<sup>3</sup></p>

            <h3 className="text-xl font-bold mt-16 mb-4">Multiplying exponents</h3>

            <p className="mb-5">To multiply exponents, you would add the exponents together.</p>

            <p className="mb-5">y<sup>2</sup>y<sup>3</sup>
            <br />= y<sup>5</sup></p>

            <p className="mb-5">y×y<sup>5</sup>
            <br />= y<sup>1</sup>×y<sup>5</sup> (y<sup>1</sup> is the same as y)
            <br />= y<sup>6</sup></p>

            <h3 className="text-xl font-bold mt-16 mb-4">Multiplying Numbers and Variables</h3>

            <p className="mb-5">When you multiply both variables and numbers, you multiply as usual.</p>

            <p className="mb-5">25a
            <br />= 2×5×a
            <br />= 10×a
            <br />= 10a</p>

            <p className="mb-5"><b>1)</b> y×y×y×y</p>

            <p className="mb-5">The answer would be y<sup>4</sup>. This is because y is being multiplied by itself 4 times.</p>
            
            <p className="mb-5"><b>2)</b> 4b×8a</p>

            <p className="mb-5">The answer would be 32ab.</p>
            <p className="mb-5">4×b×8×a
            <br />= 8×4×a×b
            <br />= 32ab</p>

            <h3 className="text-xl font-bold mt-16 mb-4">Multiplying Brackets</h3>

            <p className="mb-5">When you are multiplying brackets, you have to multiple everything by the term outside the bracket.</p>

            <p className="mb-5">8(x+5)
            <br />= 8×x+8×5
            <br />= 8x+40</p>

            <h3 className="text-xl font-bold mt-16 mb-4">Multiplying Two Brackets</h3>

            <p className="mb-5">When you multiply two brackets together, you can use FOIL. Firstly, you multiply the first terms from each bracket. Then you multiply the outer terms from each bracket. Then you multiply the inner terms from each bracket. Then you multiply the last terms from each bracket.</p>
            <img className="w-[560px] max-w-full" src={foil} alt="Foil example"/>
            <sub>© James Blair 2022</sub>

            <h3 className="text-xl font-bold mt-16 mb-4">Real World Example</h3>

            <p className="mb-5">Exponents can show how big something is.</p>
            <p className="mb-5">For example, if you wanted to write down the mass of the earth, you may write 5.972 × 10<sup>24</sup> kg.<br />This is called scientific notation. If we did not have scientific notation, we would have to write out so many decimals!</p>

            <p className="mb-5">Exponents are also used when calculating compound interest.
            <br />Over time, the amount of interest increases exponentially.</p>
        </div>
    );
}

export default Multiplication;
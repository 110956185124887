import Navbar from './features/Navbar'
import Home from './pages/Home'
import Basics from './pages/Basics'
import Multiplication from './pages/Multiplication'
import Quiz from './pages/Quiz'
import {
  Route, 
  Routes
} from "react-router-dom";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/basics" element={<Basics />} />
        <Route path="/multiplication" element={<Multiplication />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

const NoMatch = () => {
  return (
    <div className="m-5">
      <h2 className="text-2xl">404</h2>
      <p>Could not find the content you were searching for.</p>
    </div>
  );
}

export default App;
import { NavLink } from "react-router-dom"

function Navbar() {
    return (
        <div className="poppins text-black flex p-8 justify-between items-center">
            <h1 className="font-bold w-4/12 invisible md:visible">Algebra Learners</h1>
            <ul className="flex justify-center w-4/12">
                <li className="mx-3"><NavLink to="/">Home</NavLink></li>
                <li className="mx-3"><NavLink to="/basics">Basics</NavLink></li>
                <li className="mx-3"><NavLink to="/multiplication">Multiplication</NavLink></li>
            </ul>
            <span className="flex w-4/12 justify-end"></span>
        </div>
    );
}

export default Navbar;
import { Link } from "react-router-dom"

function Home() {
    return (
        <div className="m-8">
            <div className="bg-gradient-to-b from-cyan-500 to-blue-500 p-12 py-32 text-center text-white rounded-2xl poppins">
                <h2 className="text-2xl">Algebra Learners</h2>
                <p>
                    Educational content for learning Algebra
                </p>
            </div>
            <br />
            <Link to="/basics">
                <div className="p-10 bg-neutral-100 my-2 rounded-2xl">
                    <h2 className="text-2xl">Basics</h2>
                    <p>
                        Learn Algebra basics.
                    </p>
                </div>
            </Link>
            <Link to="/multiplication">
                <div className="p-10 bg-neutral-100 my-2 rounded-2xl">
                    <h2 className="text-2xl">Multiplication</h2>
                    <p>
                        Algebra multiplication basics.
                    </p>
                </div>
            </Link>
        </div>
    );
}

export default Home;